import React, { useEffect, useState, useRef } from 'react'

import AutocompleteContent from './Content'
import { CompanyType } from '../../types/company'
import { searchCompanies } from '../../helpers/queries/search'

export type AutocompleteProps = {
  placeholder: string
  currentValue: string
  companies: CompanyType[] | undefined
  setCurrentValue: (value: string) => void
  setCompanies: (results: CompanyType[]) => void
  setIsOverlay: (isDropdownOpen: boolean) => void
  handleSearchCompanyById: (id: string) => Promise<void>
}

const Autocomplete = ({
  placeholder,
  handleSearchCompanyById,
  currentValue,
  setCurrentValue,
  companies,
  setCompanies,
  setIsOverlay,
}: AutocompleteProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const searchTimeout = useRef<number | null>(null)
  const parentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const { target } = event

      if (target instanceof Node) {
        if (parentRef.current && !parentRef.current.contains(target)) {
          setIsDropdownOpen(false)
        } else {
          setIsDropdownOpen(true)
        }
      }
    }
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    setIsOverlay(isDropdownOpen)
  }, [isDropdownOpen])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setCurrentValue(value)

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current)
    }

    if (!value.length) {
      setCompanies([])
      setIsLoading(false)
      setIsDropdownOpen(false)
    } else {
      setIsLoading(true)
      setIsDropdownOpen(true)
      searchTimeout.current = setTimeout(() => {
        search(value)
        setIsLoading(false)
      }, 500)
    }
  }

  const search = async (value: string) => {
    const results = await searchCompanies({
      companyName: value,
    })
    setCompanies(results)
  }

  return (
    <AutocompleteContent
      placeholder={placeholder}
      handleChange={handleChange}
      companies={companies}
      handleSearchCompanyById={handleSearchCompanyById}
      isLoading={isLoading}
      currentValue={currentValue}
      parentRef={parentRef}
      isDropdownOpen={isDropdownOpen}
    />
  )
}

export default Autocomplete
