export const getGradeColor = (grade: string | undefined): string => {
  switch (grade) {
    case 'E':
      return '#E73D08'
    case 'D':
      return '#EE8200'
    case 'C':
      return '#FECC02'
    case 'B':
      return '#86BC2B'
    case 'A':
      return '#00823F'
    default:
      return ''
  }
}
