import { Title } from './styles'

type TitleType = {
  text: string
}

const H2 = ({ text }: TitleType) => {
  return <Title>{text}</Title>
}

export default H2
