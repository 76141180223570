import { useState } from 'react'

import Autocomplete from '../../components/Autocomplete/Container'
import H1 from '../../components/text/H1'
import Dropdown from '../../components/Dropdown'
import H2 from '../../components/text/H2'
import { CompanyType, CompleteCompanyType } from '../../types/company'
import {
  bcorpLogoWhiteUrl,
  bcorpWebsiteUrl,
  impactfranceWebsiteUrl,
} from '../../constants/urls'
import {
  CompanyDetails,
  Container,
  Content,
  ContentHeader,
  Header,
  IconLink,
  IconsContainer,
  LabelIcon,
  labelParagraphStyle,
} from './styles'
import Paragraph from '../../components/text/Paragraph'

type HomeProps = {
  company: CompleteCompanyType | null
  currentValue: string
  companies: CompanyType[] | undefined
  setCurrentValue: (value: string) => void
  setCompanies: (results: CompanyType[]) => void
  handleSearchCompanyById: (id: string) => Promise<void>
  handleClickShowSection: (label: string) => void
  activeSection: string | null
  labels: {
    isGradeFromImpactFrance: boolean | undefined
    isGradeFromBcorp: boolean | undefined
    impactFranceScore: string
  }
}

const HomeContent = ({
  company,
  currentValue,
  setCurrentValue,
  companies,
  setCompanies,
  handleSearchCompanyById,
  handleClickShowSection,
  activeSection,
  labels,
}: HomeProps) => {
  const [isOverlay, setIsOverlay] = useState<boolean>(false)

  const { isGradeFromImpactFrance, isGradeFromBcorp, impactFranceScore } =
    labels


    const labelSentence = isGradeFromImpactFrance && isGradeFromBcorp
    ? "L'entreprise a obtenu les labels B corp et Impact Score"
    : isGradeFromImpactFrance
    ? "L'entreprise a obtenu le label Impact Score"
    : isGradeFromBcorp
    ? "L'entreprise a obtenu le label B corp"
    : ''

  return (
    <Container>
      <Header>
        <H1 text={'Yetic Score'} />
        <Autocomplete
          placeholder={'Rechercher une entreprise...'}
          handleSearchCompanyById={handleSearchCompanyById}
          currentValue={currentValue}
          setCurrentValue={setCurrentValue}
          companies={companies}
          setCompanies={setCompanies}
          setIsOverlay={setIsOverlay}
        />
      </Header>

      {company && (
        <Content isOverlay={isOverlay}>
          <ContentHeader>
            <H2 text={company.companyName} />
            <IconsContainer>
              {isGradeFromBcorp && (
                <IconLink target='_blank' href={bcorpWebsiteUrl}>
                  <LabelIcon src={bcorpLogoWhiteUrl} />
                </IconLink>
              )}
              {isGradeFromImpactFrance && (
                <IconLink target='_blank' href={impactfranceWebsiteUrl}>
                  <LabelIcon src={impactFranceScore} />
                </IconLink>
              )}
            </IconsContainer>
          </ContentHeader>
          <Paragraph text={labelSentence} style={labelParagraphStyle} />
          <CompanyDetails>
            <Dropdown
              name='ENVIRONNEMENT'
              score={company.environment.score}
              details={company.environment.details}
              handleClick={() => handleClickShowSection('ENVIRONNEMENT')}
              isDropdownOpen={activeSection === 'ENVIRONNEMENT'}
            />

            <Dropdown
              name='HUMAIN'
              score={company.social.score}
              details={company.social.details}
              handleClick={() => handleClickShowSection('HUMAIN')}
              isDropdownOpen={activeSection === 'HUMAIN'}
              hideScore
              disableExpansion
            />
            <Dropdown
              name='FINANCE'
              score={company.finance.score}
              details={company.finance.details}
              handleClick={() => handleClickShowSection('FINANCE')}
              isDropdownOpen={activeSection === 'FINANCE'}
              hideScore
              disableExpansion
            />
          </CompanyDetails>
        </Content>
      )}
    </Container>
  )
}

export default HomeContent
