import axios from 'axios'

import { BACKEND_URL } from '../../constants/urls'
import { CompanyType } from '../../types/company'
import { replaceSpecialCharacters } from '../replaceSpecialCharacters'

export type SearchInputType = {
  companyName: string
}

export const searchCompanies = async ({ companyName }: SearchInputType) => {
  const response = await axios.get(
    `${BACKEND_URL}addon/companies?name=${replaceSpecialCharacters(
      companyName
    )}`
  )

  const companies = response?.data?.companies
  if (checkIfCompanyTypeArray(companies)) {
    return companies
  }

  return []
}

const checkIfCompanyTypeArray = (data: any): data is CompanyType[] => {
  return (
    Array.isArray(data) &&
    data.every(
      (company) =>
        company.id &&
        typeof company.id === 'string' &&
        company.name &&
        typeof company.name === 'string'
    )
  )
}
