import axios from 'axios'

import { BACKEND_URL } from '../../constants/urls'
import { checkIfCompanyLabelArray } from '../../types/companyLabels'
import { CompleteCompanyType } from '../../types/company'

export const getCompany = async (id: string) => {
  const response = await axios.get(`${BACKEND_URL}addon/companies/${id}`)
  if (response) {
    const data = response.data
    if (checkIfCompany(data)) {
      return data
    }
    return null
  }
  return null
}

export const checkIfCompany = (data: any): data is CompleteCompanyType => {
  return (
    data &&
    (data.id === undefined || (data.id && typeof data.id === 'string')) &&
    data.companyName &&
    typeof data.companyName === 'string' &&
    data.category !== undefined &&
    typeof data.category === 'string' &&
    data.global &&
    data.global.score !== undefined &&
    typeof data.global.score === 'string' &&
    data.global.details !== undefined &&
    typeof data.global.details === 'string' &&
    data.environment &&
    data.environment.score !== undefined &&
    typeof data.environment.score === 'string' &&
    data.environment.details !== undefined &&
    typeof data.environment.details === 'string' &&
    data.finance &&
    data.finance.score !== undefined &&
    typeof data.finance.score === 'string' &&
    data.finance.details !== undefined &&
    typeof data.finance.details === 'string' &&
    data.social &&
    data.social.score !== undefined &&
    typeof data.social.score === 'string' &&
    data.social.details !== undefined &&
    typeof data.social.details === 'string' &&
    (data.impactscore === undefined || typeof data.impactscore === 'string') &&
    data.labels !== undefined &&
    checkIfCompanyLabelArray(data.labels)
  )
}
