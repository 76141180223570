import { useEffect, useState } from 'react'

import HomeContent from './Content'
import { getCompany } from '../../helpers/queries/company'
import { CompanyType, CompleteCompanyType } from '../../types/company'
import { CompanyLabel } from '../../types/companyLabels'
import { getImpactscoreUrl } from '../../helpers/getImpactScoreUrl'

const HomeContainer = () => {
  const [companyId, setCompanyId] = useState<string | null>(null)
  const [company, setCompany] = useState<CompleteCompanyType | null>(null)
  const [companies, setCompanies] = useState<CompanyType[] | undefined>()
  const [currentValue, setCurrentValue] = useState<string>('')
  const [activeSection, setActiveSection] = useState<string | null>(
    'ENVIRONNEMENT'
  )

  useEffect(() => {
    if (companyId) {
      ;(async () => {
        const response = await getCompany(companyId)
        setCompany(response)
      })()
    }
  }, [companyId])

  useEffect(() => {
    setCurrentValue('')
  }, [])

  const handleSearchCompanyById = async (id: string) => {
    setCompanies([])
    setCurrentValue('')
    setCompanyId(id)
  }

  const handleClickShowSection = (section: string) => {
    setActiveSection((prevSection) =>
      prevSection === section ? null : section
    )
  }

  const isGradeFromImpactFrance = company?.labels?.includes(
    CompanyLabel.IMPAKT_FRANCE
  )
  const isGradeFromBcorp = company?.labels?.includes(CompanyLabel.B_CORP)
  const impactFranceScore = getImpactscoreUrl(
    Math.round(Number(company?.impactscore))
  )

  const labels = {
    isGradeFromImpactFrance,
    isGradeFromBcorp,
    impactFranceScore,
  }

  return (
    <HomeContent
      company={company}
      currentValue={currentValue}
      setCurrentValue={setCurrentValue}
      companies={companies}
      setCompanies={setCompanies}
      handleSearchCompanyById={handleSearchCompanyById}
      handleClickShowSection={handleClickShowSection}
      activeSection={activeSection}
      labels={labels}
    />
  )
}

export default HomeContainer
