import styled from '@emotion/styled'

import { Colors } from '../../../constants/colors'
import { FontSize } from '../../../constants/fonts'
import { Padding } from '../../../constants/padding'

export const Container = styled.input`
  background: ${Colors.WHITE};
  border: 1px solid ${Colors.WHITE};
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid ${Colors.WHITE};
  width: 100%;
  box-sizing: border-box;
  padding: ${Padding.P1};
  font-size: ${FontSize.S2};

  ::placeholder {
    color: ${Colors.YETIC_BLUE};
    opacity: 0.6;
  }

  :focus {
    outline: none;
  }
`
