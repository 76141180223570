import styled from '@emotion/styled'

import { Colors } from '../../../constants/colors'
import { Margin } from '../../../constants/margin'
import { Padding } from '../../../constants/padding'
import { FontWeight } from '../../../constants/fonts'

export const Container = styled.div`
  width: 100%;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
`

export const Dropdown = styled.ul<{
  isDropdownOpen: boolean
  isLoading: boolean
}>`
  display: ${(props) => (props.isDropdownOpen ? 'block' : 'none')};
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  background-color: ${Colors.WHITE};
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 10px rgba(20, 42, 76, 0.2);
  padding: ${Padding.P2} ${Padding.P2} 0 ${Padding.P2};
  max-height: 200px;
  overflow-y: auto;
  margin: 2px 0 0 0;

  > li {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: ${Margin.M1};
    font-size: 14px;
    font-weight: ${FontWeight.BOLD};
    margin-bottom: ${Margin.M1};
    opacity: ${(props) => (props.isLoading ? 0.6 : 1)};
    padding: ${Padding.P1} 0;
  }
`
