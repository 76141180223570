import styled from '@emotion/styled'

import { FontSize, FontWeight } from '../../../../constants/fonts'
import { Colors } from '../../../../constants/colors'

export const Title = styled.h1`
  font-size: ${FontSize.S4};
  font-weight: ${FontWeight.BOLD};
  color: ${Colors.WHITE};
  margin: 0;
  text-align: center;
  ::first-letter {
    text-transform: capitalize;
  }
`
