export const replaceSpecialCharacters = (string: string) =>
  string
    .toLowerCase()
    .replace(/[àâ]/g, 'a')
    .replace(/[ç]/g, 'c')
    .replace(/[éèêë]/g, 'e')
    .replace(/[îï]/g, 'i')
    .replace(/[ôö]/g, 'o')
    .replace(/[ùûü]/g, 'u')
    .replace(/[-_./;,'"]+/g, ' ')
    .trim()
