import { Title } from './styles'

type H1Type = {
  text: string
}

const H1 = ({ text }: H1Type) => {
  return <Title>{text}</Title>
}

export default H1
