import { IconType } from './types'

const Chevron = ({
  width = '14',
  height = '12',
  color = 'rgba(20, 42, 76, 0.9)',
  style,
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox='0 0 22 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.0006 1.17223e-06C10.4996 -0.00044847 10.007 0.128464 9.5704 0.374259C9.13382 0.620053 8.7681 0.974403 8.50864 1.403L0.398636 14.94C0.141033 15.3804 0.00359463 15.8808 6.95601e-05 16.391C-0.00345551 16.9013 0.127056 17.4035 0.378549 17.8474C0.630042 18.2914 0.993699 18.6615 1.43315 18.9208C1.87259 19.1801 2.37242 19.3195 2.88264 19.325H19.1076C19.6174 19.3214 20.1173 19.1841 20.5574 18.9269C20.9976 18.6697 21.3625 18.3015 21.6158 17.8591C21.8691 17.4167 22.002 16.9156 22.0011 16.4059C22.0002 15.8961 21.8655 15.3955 21.6106 14.954L13.4946 1.408C13.2349 0.978848 12.8689 0.623851 12.4321 0.377234C11.9953 0.130616 11.5023 0.00069731 11.0006 1.17223e-06Z'
        fill={color}
      />
    </svg>
  )
}

export default Chevron
