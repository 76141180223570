export enum CompanyLabel {
  IMPAKT_FRANCE = "impaktfrance",
  B_CORP = "bcorp",
  GREAT_PLACE_TO_WORK = "greatplacetowork"
}

export const checkIfCompanyLabel = (val: any): val is CompanyLabel => {
  return Object.values(CompanyLabel).includes(val)
}

export const checkIfCompanyLabelArray = (val: any): val is CompanyLabel[] => {
  return Array.isArray(val) && val.every((v) => checkIfCompanyLabel(v))
}
