export enum FontSize {
  S1 = '8px',
  S2 = '16px',
  S3 = '24px',
  S4 = '32px',
  S5 = '40px',
  S6 = '48px',
  S7 = '56px',
}

export enum FontWeight {
  THIN = 300,
  REGULAR = 400,
  MEDIUM = 500,
  BOLD = 600,
  BOLDER = 700,
}
