import Home from './pages/Home/Container'

const routes = [
  {
    path: '/',
    component: <Home />,
  },
]

export default routes
