import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import { Margin } from '../../../constants/margin'
import { Padding } from '../../../constants/padding'

export const Container = styled.div`
  padding: ${Padding.P2};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: ${Margin.M6};

  @media (min-width: 425px) {
    padding: ${Padding.P6};
  }
`

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: ${Margin.M6};
`

export const Content = styled.div<{ isOverlay: boolean }>`
  flex: 1;
  filter: ${(props) => props.isOverlay && 'blur(2px) opacity(0.2)'};

  @media (min-width: 500px) {
    background-color: rgba(89, 206, 206, 0.1);
    border-radius: 6px;
    padding: ${Padding.P6};
  }
`

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${Margin.M5};
  margin-bottom: ${Margin.M2};

  @media (min-width: 500px) {
    justify-content: center;
  }
`

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Margin.M1};
`

export const IconLink = styled.a`
  width: fit-content;
  height: fit-content;
`

export const LabelIcon = styled.img`
  width: 36px;
`

export const CompanyDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Margin.M4};

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`

export const labelParagraphStyle: CSSProperties = {
  marginBottom: Margin.M5,
  fontSize: '14px',
}
