import { CSSProperties } from 'react'

import { ParagraphText } from './styles'

type ParagraphProps = {
  text: string
  style?: CSSProperties
}

const Paragraph = ({ text, style }: ParagraphProps) => {
  return <ParagraphText style={style}>{text}</ParagraphText>
}

export default Paragraph
