import { MouseEventHandler } from 'react'

import { getGradeColor } from '../../helpers/getGradesColor'
import Chevron from '../icons/Chevron'
import {
  closeDropdownChevron,
  openDropdownChevron,
  Container,
  Header,
  Bullet,
  EmptyBullet,
  DropdownContent,
} from './styles'

export type DropdownProps = {
  name: string
  score: string
  details: string
  isDropdownOpen: boolean
  handleClick?: MouseEventHandler<HTMLAnchorElement>
  disableExpansion?: boolean
  hideScore?: boolean
}

const Dropdown = ({
  name,
  score,
  details,
  isDropdownOpen,
  handleClick,
  disableExpansion,
  hideScore,
}: DropdownProps) => {
  const bgColor = getGradeColor(score)
  const dropdownChevronStyle = isDropdownOpen
    ? openDropdownChevron
    : closeDropdownChevron

  return (
    <Container>
      <Header onClick={!disableExpansion ? handleClick : undefined}>
        {hideScore ? (
          <EmptyBullet>-</EmptyBullet>
        ) : (
          <Bullet bgColor={bgColor} />
        )}
        <p>{name}</p>
        {!disableExpansion && <Chevron style={dropdownChevronStyle} />}
      </Header>
      {isDropdownOpen && !disableExpansion && (
        <DropdownContent>
          <div dangerouslySetInnerHTML={{ __html: details }}></div>
        </DropdownContent>
      )}
    </Container>
  )
}

export default Dropdown
