import React from 'react'

import Input from '../Input'
import { CompanyType } from '../../types/company'
import { Container, Dropdown } from './styles'

type AutocompleteType = {
  placeholder: string
  companies: CompanyType[] | undefined
  isLoading: boolean
  currentValue: string
  parentRef: React.MutableRefObject<any>
  isDropdownOpen: boolean
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleSearchCompanyById: (id: string) => Promise<void>
}

const AutocompleteContent = ({
  placeholder,
  handleChange,
  companies,
  handleSearchCompanyById,
  isLoading,
  currentValue,
  parentRef,
  isDropdownOpen,
}: AutocompleteType) => {
  const noSearchInProgress = currentValue.length < 1 && !isLoading
  const foundedResults = companies && companies.length > 0 && !isLoading

  return (
    <Container ref={parentRef}>
      <Input
        placeholder={placeholder}
        value={currentValue}
        type='text'
        onChange={handleChange}
      />
      {foundedResults ? (
        <Dropdown isDropdownOpen={isDropdownOpen} isLoading={isLoading}>
          {companies?.map((company) => (
            <li
              key={company.id}
              onClick={() => {
                handleSearchCompanyById(company.id)
              }}
            >
              {company.name}
            </li>
          ))}
        </Dropdown>
      ) : noSearchInProgress ? (
        ''
      ) : (
        <Dropdown isDropdownOpen={isDropdownOpen} isLoading={isLoading}>
          <li>{isLoading ? 'Chargement...' : 'Aucun résultat trouvé'}</li>
        </Dropdown>
      )}
    </Container>
  )
}

export default AutocompleteContent
