import React from 'react'

import { Container } from './styles'

export type InputProptypes = {
  value: string
  type: string
  id?: string
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Input = ({ value, type, onChange, placeholder, id }: InputProptypes) => {
  return (
    <>
      <Container
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        id={id}
      />
    </>
  )
}

export default Input
