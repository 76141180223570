import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import { Colors } from '../../../constants/colors'
import { FontSize, FontWeight } from '../../../constants/fonts'
import { Margin } from '../../../constants/margin'
import { Padding } from '../../../constants/padding'

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  border-radius: 8px;
  color: ${Colors.YETIC_DARK};
  background-color: ${Colors.WHITE};

  @media (min-width: 768px) {
    width: 70%;
    margin: auto;
  }

  @media (min-width: 1024px) {
    width: 33%;
    margin: 0;
  }
`

export const Bullet = styled.div<{ bgColor: string }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.bgColor};
  margin-right: ${Margin.M1};
`

export const EmptyBullet = styled.div`
  width: 20px;
  text-align: center;
  margin-right: ${Margin.M1};
`

export const Header = styled.a`
  font-size: ${FontSize.S2};
  font-weight: ${FontWeight.BOLD};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  padding: 0 ${Padding.P3};
  padding-right: 5%;
  margin: 0;
  box-shadow: 0 2px 10px rgba(20, 42, 76, 0.2);
  background-color: ${Colors.WHITE};
  border-radius: 15px;
  z-index: 2;
`

export const openDropdownChevron: CSSProperties = {
  marginLeft: 'auto',
  transform: 'rotate(180deg)',
  transition: 'transform 300ms',
}

export const closeDropdownChevron: CSSProperties = {
  marginLeft: 'auto',
  transform: 'rotate(0deg)',
  transition: 'transform 300ms',
}

export const DropdownContent = styled.div`
  padding: ${Padding.P2};
`
